import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"


function Privacy() {
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumb title="Dashboard" breadcrumbItem="Privacy & Policy" />
        SmartDrive Corporation and its affiliated companies (“SmartDrive” “we” or “us”) are firmly committed to protecting your privacy. You should understand what we do with data relating to you (“personal information”) which we collect when you visit this website (SmartDrive.com), our country-specific sites or any other websites to which this SmartDrive Website Privacy Notice (“Privacy Notice”) applies (collectively, the “Sites”). As a global company, SmartDrive has a number of legal entities in different jurisdictions which are responsible for the personal information which they collect independently and which may be processed on their behalf by SmartDrive U.S. Corporation and its affiliates. The data controller for personal information collected from a visitor to the Sites is SmartDrive U.S. Corporation, 211 Quality Circle, College Station, Texas, United States of America or the affiliate specified on the Site that references this Privacy Notice.

We may supplement this Privacy Notice to address specific situations. All supplemental notices should be read together with this Privacy Notice.
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Privacy
