import config from "../config"
import { post, get, put, del, patch } from "../helpers/api_helper"

export const DriverAPI = {
  driverList: async data => {

    if(data.first_load) {

      return await get(
        `${config.API_URL}api/driver/list?limit=${data.limit}&page=${data.page}`
      )
    }

    return await get(
      `${config.API_URL}api/driver/list?from=${data.from}&to=${data.to}&limit=${data.limit}&page=${data.page}`
    )
  },
  driverListFilter: async data => {
    return await get(
      `${config.API_URL}api/driver/list?searchBy=${data.searchBy}&searchValue=${data.searchValue}&limit=${data.limit}&page=${data.page}`
    )
  },
  getDriver: async id => {
    return await get(`${config.API_URL}api/driver/${id}`)
  },
  addDriver: async data => {
    return await post(`${config.API_URL}api/driver/add`, data)
  },
  changeDriverApproval: async data => {
    return await put(`${config.API_URL}api/driver/change-approval`, data)
  },
  getDriverActivity: async (driverId, date) => {
    return await get(`${config.API_URL}api/driver/activity/${driverId}/${date}`)
  },
  getDriversSummary: async data => {
    return await get(
      `${config.API_URL}api/drivers/summary?from=${data.from}&to=${data.to}`
    )
  },
  getDashboardSummary: async data => {
    return await get(
      `${config.API_URL}api/dashboard-summary`
    )
  },
  getDriversWithoutFilter: async data => {
    return await get(
      `${config.API_URL}api/drivers/nofilter`
    )
  },
  DeleteDriver: async (driverId) => {
    return await del(`${config.API_URL}api/driver/${driverId}`)
  },

  EnableDisableDriver: async (driverId, status) => {
    return await patch(`${config.API_URL}api/driver/${driverId}/${status}`)
  },

  AddEstimatedKms: async (data) => {
    return await post(`${config.API_URL}api/driver/estimated-kms`, data)
  },
  EstimatedKMsList: async(driverId, data) => {
    return await get(
      `${config.API_URL}api/drivers/${driverId}/estimated-kms?limit=${data.limit}&page=${data.page}`
    )
  }
}
