import React, { useEffect, useState, useCallback } from "react"
import { Container, Row, Card, CardBody, Col, Button } from "reactstrap"
import MUIDataTable from "mui-datatables"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import { UserAPI } from "./../../apis/UserAPI"
import { Link, useHistory } from "react-router-dom"
import { canPerformAction } from "./../../helpers/core_helper"
import { confirmAlert } from 'react-confirm-alert';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const Users = () => {
  const [users, setUsers] = useState([])
  const [loader, setLoader] = useState(true)
  const history = useHistory()
  const { addToast } = useToasts();
  const [delFlag, setDelFlag] = useState();
  
  useEffect(() => {
    UserAPI.getUsersList().then(res => {
      setLoader(false)
      if (res) {
        setUsers(res.data)
      }
    })
    setDelFlag(false);
  }, [delFlag])

  const handelDeleteUser = useCallback( value => {
    const options = {
      title: 'Confirmation!!',
      message: 'Are You sure to remove the user',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            UserAPI.DeleteUser(value).then((response) => {
              console.log(response);
              setDelFlag(true);
              addToast(response.message, { appearance: "success", autoDismiss: true });
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name"
    };
    
    confirmAlert(options);
  }, [])

  const columns = [
    { name: "id", label: "#" },
    { name: "name", label: "User Name" },
    { name: "email", label: "Email" },
    { name: "Role.name", label: "Role" },
    { name: "users", label: "", options: {display: false} },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {canPerformAction("users", "update") && (
                <Button
                  type="button"
                  title="Edit User"
                  outline
                  color="primary"
                  size="sm"
                  onClick={() => history.push(`/edit-users/${value}`)}
                >
                  <i className="bx bx-edit align-middle"></i>
                </Button>
              )}
              {/* <Button type="button" title="Delete Region" outline color="danger" size="sm">
                  <i className="bx bx-trash align-middle"></i>
                </Button> */}
              {( tableMeta.rowData[4] && !tableMeta.rowData[4].length && canPerformAction("users", "delete")) && (
                <>
                  {" "}
                  <Button type="button" title="Delete User" outline color="danger" size="sm" onClick={() => { handelDeleteUser(value) }} >
                    <i className="bx bx-trash align-middle"></i>
                  </Button>
                </>
              )}
            </>
          )
        },
      },
    },
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    serverSide: false,
    search: false,
    enableNestedDataAccess: ".",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {canPerformAction("users", "add") && (
                        <div>
                          <Link
                            to="/add-users"
                            type="button"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus-circle"></i>
                            <span> Add User</span>
                          </Link>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {loader ? (
                    <Loader layout="table" />
                  ) : (
                    <MUIDataTable
                      title={"Users List"}
                      data={users}
                      columns={columns}
                      options={options}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Users
