import React, { useState, useEffect, useCallback, useLayoutEffect } from "react"
import { Container, Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import moment from "moment"
import MUIDataTable from "mui-datatables"
import { Link, useHistory } from "react-router-dom"
import { DateRangePicker } from "rsuite"
import { confirmAlert } from 'react-confirm-alert';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { DriverAPI } from "../../apis/DriverAPI"
import { canPerformAction } from "../../helpers/core_helper"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { DriverSummary } from "../../components/Driver/DriverSummary"
import { SendNotificationPopup } from "../../components/Notification/SendNotificationPopup"
import Loader from "../../components/Common/Loader"

function DriverList(props) {
  document.title = "Drivers | Smart Drive Admin Application";
  const [loader, setLoader] = useState(true)
  const [delFlag, setDelFlag] = useState();
  const [drivers, setDrivers] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [driverId, setDriverId] = useState()
  const [viewNofiPopup, setViewNofiPopup] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })
  const [data, setData] = useState({})

  const history = useHistory()
  const { addToast } = useToasts();
  
  useEffect(() => {
    setLoader(true)
    const queryStringObject = {
      from: dateRange.from,
      to: dateRange.to,
      limit,
      page,
      first_load : firstLoad
    }
    DriverAPI.driverList(queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setDrivers(res.data.drivers);
        setCount(res.data.total);
      }
    })
    setDelFlag(false);
    return () => {
      window.onbeforeunload = () => {
        localStorage.removeItem("driverListFilters")
      }
    }
  }, [dateRange, page, limit, delFlag])

  useLayoutEffect(() => {
    const driverListFilters = JSON.parse(
      localStorage.getItem("driverListFilters")
    )
    if (driverListFilters) {
      setDateRange(driverListFilters)
    }
  }, [])

  const handleListDateRangeChange = useCallback(values => {
    setDateRange({
      from: values[0],
      to: values[1],
    })
    setFirstLoad(false)
    localStorage.setItem(
      "driverListFilters",
      JSON.stringify({ from: moment(values[0]), to: moment(values[1]) })
    )
  }, [])

  const handleSendNotificationClose = () => {
    setViewNofiPopup(false)
  }

  const handelEnableDisableDriver = useCallback((driverId, status) => {
    const options = {
      title: 'Confirmation!!',
      message: 'Are You sure to update the driver status',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            DriverAPI.EnableDisableDriver(driverId, status).then((response) => {
              console.log(response);
              setDelFlag(true);
              addToast(response.message, { appearance: "success", autoDismiss: true });
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name"
    };
    
    confirmAlert(options);
  })
  const handelDeleteDriver = useCallback( value => {
    const options = {
      title: 'Confirmation!!',
      message: 'Are You sure to remove the driver',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            DriverAPI.DeleteDriver(value).then((response) => {
              console.log(response);
              setDelFlag(true);
              addToast(response.message, { appearance: "success", autoDismiss: true });
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name"
    };
    
    confirmAlert(options);
  }, [])

  const columns = [
    { name: "id", label: "Driver ID" },
    { name: "name", label: "Name" },
    {
      name: "current_status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {

            return value
            
          }
          else {
            
            return 'NA';
          }
        },
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* <button
                className="btn-link btn btn-"
                onClick={() =>
                  history.push(`/view-driver/${tableMeta.rowData[0]}`)
                }
              >
                View Profile
              </button> */}
              <button
                className="btn-link btn btn-"
                onClick={() => {
                  setViewNofiPopup(true)
                  setDriverId(tableMeta.rowData[0])
                }}
              >
                Send Notification
              </button>
              <button
                className="btn-link btn btn-"
                onClick={() =>
                  history.push(`/driver/activity/${tableMeta.rowData[0]}`)
                }
              >
                View Activities
              </button>
              {canPerformAction("driver", "estimated_kms") && (
                <button
                  className="btn-link btn btn-"
                  onClick={() =>
                    history.push(`/drivers/${tableMeta.rowData[0]}/estimated-kms`)
                  }
                >
                  View Estimated KMs
                </button>
              )}
              {( canPerformAction("driver", "disable")) && (
                <>
                { tableMeta.rowData[5] === true ? (
                  <Button type="button" title="Disable Driver" outline color="warning" size="sm" onClick={() => { handelEnableDisableDriver(tableMeta.rowData[0], 0) }} >
                    <i className="bx bx-error-alt align-middle"></i>
                  </Button>
                ):(
                  <Button type="button" title="Enable Driver" outline color="success" size="sm" onClick={() => { handelEnableDisableDriver(tableMeta.rowData[0], 1) }} >
                    <i className="bx bx-check-square align-middle"></i>
                  </Button>
                )} 
                </>
              )}

              {( !tableMeta.rowData[4].length && canPerformAction("driver", "delete")) && (
                <>
                  {" "}
                  <Button type="button" title="Delete Driver" outline color="danger" size="sm" onClick={() => { handelDeleteDriver(tableMeta.rowData[0]) }} >
                    <i className="bx bx-trash align-middle"></i>
                  </Button>
                </>
              )}

              {/* <a href={`${ tableMeta.rowData[4] ? `tel:` + tableMeta.rowData[4].mobile : "#" }`}
                className="btn-link btn btn-"
                // onClick={() => console.log(value, tableMeta)}
              >
                Connect To Driver
              </a>{' '}
               */}
            </>
          )
        },
      },
    },
    {
      name: "Trips",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "is_activated",
      label: "",
      options: {
        display: false,
      },
    },
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [10, 25, 50, 100],
    downloadOptions: { filename: "driverList.csv", separator: "," },
    elevation: 0,
    serverSide: true,
    search: false,
    page,
    count,
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      if (action === "changePage") {
        // console.log("Go to page", tableState.page);
        setPage(tableState.page)
      } else if(action === "changeRowsPerPage") {
        setLimit(tableState.rowsPerPage)
      }
    }
  }

  const updateData = e => {
    setData({
        ...data,
        [e.target.name]: e.target.value
    })
  }

  const searchSubmit = e => {
    e.preventDefault()
    // console.log(data)
    
    setLoader(true)
    const queryStringObject = {
      searchBy: data.searchBy,
      searchValue: data.searchValue,
      limit,
      page
    }
    // console.log(queryStringObject);
    DriverAPI.driverListFilter(queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setDrivers(res.data.drivers);
        setCount(res.data.total);
      }
    })
    setDelFlag(false);
  }

  const handleReset = () => {
    window.location.reload();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Driver List" />
          <DriverSummary />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loader ? (
                    <Loader layout="table" />
                  ) : (
                    <>
                      <Row>
                        <Col>
                          {canPerformAction("driver", "add") && (
                            <div>
                              <Link
                                to="/add-driver"
                                type="button"
                                className="btn btn-primary"
                              >
                                <i className="bx bx-plus-circle"></i>
                                <span> Add Driver</span>
                                {/* <span>{props.t("Add Driver")}</span> */}
                              </Link>
                            </div>
                          )}
                        </Col>
                        <Col>
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Label
                              className="mr-2"
                              style={{ marginTop: "5px", marginRight: "11px" }}
                            >
                              Filter By
                            </Label>
                            <DateRangePicker
                              size="sm"
                              placeholder="Select Date Range"
                              character="-"
                              format="dd/MM/yyyy"
                              placement="auto"
                              value={[
                                new Date(dateRange.from),
                                new Date(dateRange.to),
                              ]}
                              onChange={handleListDateRangeChange}
                              className=""
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <form className="form-horizontal" onSubmit={searchSubmit}>
                            {/* <div
                              style={{ display: "flex", justifyContent: "end" }}
                            > */}
                            <div className="form-group row" style={{display: "flex", justifyContent: "end", marginTop: "10px" }}>
                              <div className="col-md-2">
                                <select value={data.searchBy} name="searchBy" required className="form-control" onChange={updateData}>
                                  <option value="">-Search By-</option>
                                  <option value='name'>Name</option>
                                  <option value='email'>Email</option>
                                </select>
                              </div>
                              <div className="col-md-2">
                                {/* <Label className="form-label">Search value</Label> */}
                                  <input className="form-control" value={data.searchValue} required placeholder="abc / abc@gmail.com" name="searchValue" onChange={updateData} type="text"></input>
                              </div>
                              <div className="col-md-2" style={{ display: "flex", justifyContent: "end" }}>
                                  <button className="btn btn-sm btn-primary" style={{ marginRight: "5px"}} type="submit">Search</button>
                                  <button className="btn btn-sm btn-warning" type="button" onClick={handleReset}>Reset</button>
                              </div>
                            </div>
                          </form>
                        </Col>
                      </Row>
                      <MUIDataTable
                        title={""}
                        data={drivers}
                        columns={columns}
                        options={options}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* open={viewNofiPopup} onClose={handleSendNotificationClose} */}
        <SendNotificationPopup
          driverId={driverId}
          open={viewNofiPopup}
          onClose={handleSendNotificationClose}
        />
      </div>
    </React.Fragment>
  )
}

export default DriverList
