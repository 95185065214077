

export function canPerformAction(module, action) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const permissions = obj.role.permissions;
    if( permissions ) {
        return (permissions[module] && permissions[module][action]) ? true : false;
    }
    return false;
}


export function getUserRole(id = true) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if(id) {
        return obj.role.id;
    } else {
        return obj.role.name;
    }
}