import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { RegionAPI } from "../../apis/RegionAPI"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "../../components/Common/Loader"
import { CountryAPI } from "../../apis/CountryAPI"

function EditCountry() {
  const history = useHistory()
  const [regions, setRegions] = useState([])
  const [country, setCountry] = useState({})
  const { addToast } = useToasts()
  const params = useParams()
  const [loader, setLoader] = useState(false)

  const updateCountries = value => {
    console.log("params", params)
    CountryAPI.updateCountry(params.countryId, value)
      .then(res => {
        if (res) {
          history.push("/countries")
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.errors) {
          err.response.data.errors.map(error => {
            console.log(error.msg)
            addToast(error.msg, { appearance: "error", autoDismiss: true })
          })
        }
      })
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: country,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter country name"),
      region_id: Yup.string().required("Please select region"),
      status: Yup.string().required("Please select status"),
    }),
    onSubmit: values => {
      updateCountries(values)
    },
  })

  useEffect(() => {
    RegionAPI.getActiveRegions().then(res => {
      if (res) {
        setRegions(res.data.map(reg => ({ label: reg.name, value: reg.id })))
      }
    })
  }, [])

  useEffect(() => {
    setLoader(true)
    CountryAPI.getCountry(params.countryId)
      .then(res => {
        setLoader(false)
        if (res.data) {
          setCountry(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })

      // console.log(country, "sssssssssss'");
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Update Country" />
          <Row>
            <Col lg={12}>
              <Card>
                {loader === true ? (
                  <Loader layout="table" />
                ) : (
                  <CardBody>
                    <Row className="mb-2">
                      <Col>
                        <button
                          type="button"
                          className="btn btn-link  "
                          onClick={() => history.goBack()}
                        >
                          <i className="bx bx-arrow-back"></i>
                          <span> Back</span>
                        </button>
                      </Col>
                    </Row>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Label className="form-label">Country Name</Label>
                          <Input
                            name="name"
                            className="form-control"
                            placeholder="Enter Country Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <Label className="form-label">Status</Label>
                          <Input
                            name="status"
                            className="form-control"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status == 1 ? "1" : "0"}
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }
                          >
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="col-md-6 mt-3">
                          <Label className="form-label">Region</Label>
                          <Input
                            name="region_id"
                            className="form-control"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.region_id || ""}
                            invalid={
                              validation.touched.region_id &&
                              validation.errors.region_id
                                ? true
                                : false
                            }
                          >
                            {regions.map((reg, index) => (
                              <option key={index} value={reg.value}>
                                {reg.label}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.region_id &&
                          validation.errors.region_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.region_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button type="submit" color="primary">
                          Update Country
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCountry
