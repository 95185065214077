import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

const Logout = props => {
  useEffect(() => {
    try {
      localStorage.removeItem("authUser");
      localStorage.removeItem("driverFilters");
      localStorage.removeItem("driverListFilters");

      props.history.push("/login");
    } catch (error) {
      console.log(error);
    }
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
