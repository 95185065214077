import React from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useToasts } from 'react-toast-notifications';
import Breadcrumb from "../../components/Common/Breadcrumb"
import { RegionAPI } from "../../apis/RegionAPI"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

function AddRegion() {
  const history = useHistory()
  const { addToast } = useToasts();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: name || "",
      status: 1
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .required("Please Enter Region Name"),
      status: Yup.string()
    }),
    onSubmit: (values, action) => {
      addRegion(values, action)
    },
  })

  const addRegion = (value, action) => {
    // setLoader(true)
    RegionAPI.addRegion(value).then(res => {
      // setLoader(false)
      //Token.saveToken(res.data.user, res.data.token, res.data.settings);
      if (res) {
        history.push("/regions")
      }
    }).catch((err) => {
      if(err.response.data && err.response.data.errors) {
        err.response.data.errors.map((error) => {
          console.log(error.msg);
          addToast(error.msg, { appearance: 'error', autoDismiss: true });
        });
      }

    }).finally(() => {
      action.setSubmitting(false);
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumb title="Dashboard" breadcrumbItem="Add Region" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <button
                        type="button"
                        className="btn btn-link  "
                        onClick={() => history.goBack()}
                      >
                        <i className="bx bx-arrow-back"></i>
                        <span> Back</span>
                      </button>
                    </Col>
                  </Row>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Region Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <Label className="form-label">Status</Label>
                        <Input
                          name="status"
                          className="form-control"
                          type="select"
                          onChange={validation.handleChange}
                          value={validation.values.status || ""}
                          invalid={
                            validation.touched.status && validation.errors.status
                              ? true
                              : false
                          }
                        >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                        </Input>
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button disabled={validation.isSubmitting} type="submit" color="primary">
                        Add Region
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRegion
