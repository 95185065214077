import config from "../config"
import { post, get, put } from "../helpers/api_helper"

export const NotificationAPI = {
  List: async data => {
    return await get(
      `${config.API_URL}api/notifications?from=${data.from}&to=${data.to}&driver=${data.driver}&limit=${data.limit}&page=${data.page}`
    )
  },
  getBellNotifications: async () => {
    return await get(`${config.API_URL}api/notifications/bell`)
  },
  sendNotification: async (driverId, tripId, data) => {
    tripId = tripId ? tripId : ""
    return await post(
      `${config.API_URL}api/notification/send/${driverId}/${tripId}`,
      data
    )
  },
  markAsReadNotification: async id => {
    return await put(`${config.API_URL}api/notifications/${id}/read`)
  },
}
