import axios from "axios"
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(req => {
  const auth = JSON.parse(localStorage.getItem("authUser"))
  if (auth) {
    req.headers.Authorization = `Bearer ${auth.accessToken}`
  }
  return req
})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.replace("/login")
      localStorage.removeItem("authUser")
    } else {
      return Promise.reject(error);
    }
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
