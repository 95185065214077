import React, { useEffect, useState } from "react"
import { Container, Row, Card, CardBody, Col, Button } from "reactstrap"
import MUIDataTable from "mui-datatables"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import { canPerformAction } from "../../helpers/core_helper"
import { OperationalAreaAPI } from "apis/OperationalAreaAPI"

const OperationalAreaList = () => {
  document.title = "Operational Areas | Smart Drive Admin Application";
  const [operationalArea, setOperationalArea] = useState([])
  const history = useHistory()

  useEffect(() => {
    OperationalAreaAPI.getOperationalAreasList().then(res => {
      if (res) {
        setOperationalArea(res.data.operationalAreas)
      }
    })
  }, [])

  const columns = [
    { name: "id", label: "#" },
    { name: "name", label: "Operational Area Name" },
    { name: "country.name", label: "Country Name" },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: value => {
          return value == "1" ? (
            <span className="font-size-12 badge-soft-success badge bg-secondary">
              Active
            </span>
          ) : (
            <span className="font-size-12 badge-soft-danger badge bg-secondary">
              In-Active
            </span>
          )
        },
      },
    },
  ]

  if (canPerformAction("operational_areas", "update")) {
    columns.push({
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Button type="button" title="Edit Region" outline color="primary" size="sm" onClick={() =>
                  history.push(`/edit-operational-areas/${value}`)
                }>
                <i className="bx bx-edit align-middle"></i>
              </Button>{' '}
            </>
          )
        },
      },
    });
  }

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    serverSide: false,
    search: false,
    enableNestedDataAccess: ".",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Operational Area" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {canPerformAction("operational_areas", "add") && (
                        <div>
                          <Link
                            to="/add-operational-area"
                            type="button"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus-circle"></i>
                            <span> Add Operational Area</span>
                          </Link>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <MUIDataTable
                    title={"Operational Area List"}
                    data={operationalArea}
                    columns={columns}
                    options={options}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default OperationalAreaList