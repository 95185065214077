import React, { useCallback, useState, useEffect, useLayoutEffect } from "react"
import { Row, Col, Label } from "reactstrap"
import { DateRangePicker } from "rsuite"
import moment from "moment"
import { CustomCard } from "../../components/Driver/CustomCard"
import { DriverAPI } from "../../apis/DriverAPI"
import { useHistory } from "react-router-dom"

export const DriverSummary = () => {
  const [driversSummary, setDriversSummary] = useState({
    accidentReportedCount: 0,
    incidentCount: 0,
    totalPauseTime: 0,
    unreadNotificationCount: 0,
  })

  const history = useHistory()

  const [summaryDateRange, setSummaryDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })

  useEffect(() => {
    // setLoader(true)
    const queryStringObject = {
      from: summaryDateRange.from,
      to: summaryDateRange.to,
    }
    DriverAPI.getDriversSummary(queryStringObject).then(res => {
      // setLoader(false)
      if (res) {
        setDriversSummary(res.data)
      }
    });
    return () => {
        window.onbeforeunload = () => {
          localStorage.removeItem("driverSummaryFilters")
        }
    }
  }, [summaryDateRange]);

  useLayoutEffect(() => {
    const driverSummaryFilters = JSON.parse(
      localStorage.getItem("driverSummaryFilters")
    )
    if (driverSummaryFilters) {
      setSummaryDateRange(driverSummaryFilters)
    }
  }, []);

  const handleSummaryDateRangeChange = useCallback(values => {
    // console.log("value", values)
    setSummaryDateRange({
      from: values[0],
      to: values[1],
    });
    localStorage.setItem(
        "driverSummaryFilters",
        JSON.stringify({ from: moment(values[0]), to: moment(values[1]) })
    );
  }, [])

  return (
    <>
      <Row>
        <div className="row">
          <Col>
            <div
              className="mb-2"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Label
                className="mr-2"
                style={{ marginTop: "5px", marginRight: "11px" }}
              >
                Filter By
              </Label>
              <DateRangePicker
                size="sm"
                placeholder="Select Date Range"
                character="-"
                format="dd/MM/yyyy"
                placement="auto"
                value={[
                    new Date(summaryDateRange.from),
                    new Date(summaryDateRange.to),
                  ]}
                onChange={handleSummaryDateRangeChange}
                className=""
              />
            </div>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="row">
          <CustomCard
            title={"Unread Notifications"}
            buttonText={"View notifications"}
            count={driversSummary.unreadNotificationCount}
            onClick={ () =>
              history.push(`/notifications`)
             }
          />
          <CustomCard
            title={"Total Accidents Reported"}
            buttonText={"View all Accidents"}
            count={driversSummary.accidentReportedCount}
            // onClick={console.log("Total Accident Reported")}
          />
          <CustomCard
            title={"Total Incidents Reported"}
            buttonText={"view all Incident"}
            count={driversSummary.incidentCount}
            // onClick={console.log("Total Incident Reported")}
          />
          <CustomCard
            title={"Total Pause Time"}
            buttonText={"View drivers list"}
            count={`${driversSummary.totalPauseTime} hrs`}
            onClick={ () =>
              history.push(`/drivers`)
             }
          />
        </div>
      </Row>
    </>
  )
}

// export default DriverSummary
