import React from "react"
import { Input, Label, Form, FormFeedback, TextField } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { DriverAPI } from "./../../apis/DriverAPI"
import { NotificationAPI } from "./../../apis/NotificationAPI"
import { useToasts } from 'react-toast-notifications';

const SendNotificationForm = ({ driverId, tripId, onClose }) => {


  const { addToast } = useToasts();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
      description: Yup.string().required("Please enter description"),
    }),
    onSubmit: (values, action) => {
        NotificationAPI.sendNotification(driverId, tripId, values)
        .then(res => {
            if(res.status == false) {
                addToast('Some error occure while sending Notification', { appearance: 'error', autoDismiss: true });
            } else {
                addToast('Notification sent Successfully', { appearance: 'success', autoDismiss: true });
            }
            onClose();
        })
        .catch(err => console.log("err", err)).finally(() => {
          action.setSubmitting(false);
        })
    },
  })

  return (
    <div className="p-2">
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return false
        }}
      >
        <div className="mb-3">
          <Label className="form-label">Title</Label>
          <Input
            id="title"
            name="title"
            className="form-control"
            placeholder="Enter Title"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title || ""}
            invalid={formik.touched.title && formik.errors.title ? true : false}
          />
          {formik.touched.title && formik.errors.title ? (
            <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Description</Label>
          <Input
            name="description"
            type="textarea"
            placeholder="Enter Description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description || ""}
            invalid={
              formik.touched.description && formik.errors.description
                ? true
                : false
            }
          />
          {formik.touched.description && formik.errors.description ? (
            <FormFeedback type="invalid">
              {formik.errors.description}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mt-4">
          <button disabled={formik.isSubmitting} className="btn btn-primary btn-block " type="submit">
            Send
          </button>
        </div>
      </Form>
    </div>
  )
}

export default SendNotificationForm