import React, { useEffect, useState, useCallback } from "react"
import Geocode from "react-geocode"
import { ViewMapPopup } from "../Driver/ViewMapPopup";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyAlOIZtF-orsON_uvPb3wHFQW-1W_FNtr0")

// set response language. Defaults to english.
Geocode.setLanguage("en")

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es")

Geocode.setLocationType("ROOFTOP")

// Enable or disable logs. Its optional.
Geocode.enableDebug()

function getAddress(lat, lng) {
  console.log(lat, lng)
  Geocode.fromLatLng(lat, lng).then(
    response => {
      const address = response.results[0].formatted_address
      console.log(address)
      return address
    },
    error => {
      console.error(error)
    }
  )
}

export function GetGoeAddress(props) {
  const [location] = useState({
    lat: props.latitude,
    lng: props.longitude,
  })
  const [address, seAddress] = useState("");
  const [viewMap, setViewMap] = useState(false);
  useEffect(() => {
    Geocode.fromLatLng(location.lat, location.lng).then(
      response => {
        const address = response.results[0].formatted_address
        seAddress(address)
      },
      error => {
        console.error(error)
      }
    )
  }, [location]);


  const handlePopupClose = useCallback(() => {
    setViewMap(false);
  }, [])

  const randerMapPopup = useCallback(() => {
    setViewMap(true);
  })

  return (
    <span className="text-dark p-1">
      <strong>{address}</strong>
      <i className="bx bxs-map-alt p-2"></i>  <button className="text-success btn btn-link" onClick={randerMapPopup}>View on Map</button>
      <ViewMapPopup open={viewMap} onClose={handlePopupClose} location={location} />
    </span>

  )
}
