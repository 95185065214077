import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
// import { useToasts } from "react-toast-notifications";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { DriverAPI } from "../../apis/DriverAPI";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { GetGoeAddress } from "../../components/Driver/GetGeoAddress";
// import { ModalBox } from "../../components/Common/ModalBox";
import { SendNotificationPopup } from "../../components/Notification/SendNotificationPopup";

// Timeline
import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent"
import Typography from "@mui/material/Typography"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Loader from "../../components/Common/Loader"

function Activity() {
  const [driverActivity, setDriverActivity] = useState([]);
  const [value, setValue] = React.useState(moment());
  const params = useParams();
  const history = useHistory();
  const [loader, setLoader] = useState(true)

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handlePopupClose = useCallback(() => {
    setShow(false);
  }, [])

  useEffect(() => {
    DriverAPI.getDriverActivity(params.driverId, value.format("YYYY-MM-DD"))
      .then(res => {
        setLoader(false)
        if (res.data) {
          setDriverActivity(res.data)
        }
      }).catch(err => {
        console.log(err)
      })
  }, [value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            breadcrumbItem="Driver Daily Activities"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                {loader ? (
                    <Loader />
                  ) : (
                    <>
                    <div className="row">
                      <div className="col-10 mb-4 h4 card-title">
                        <button
                          type="button"
                          className="btn btn-link  "
                          onClick={() => history.goBack()}
                        >
                          <i className="bx bx-arrow-back"></i>
                          <span> Back</span>
                          {/* <span>{props.t("Add Driver")}</span> */}
                        </button>
                      </div>
                      <div className="col-2 mb-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["day"]}
                            label="Select date"
                            value={value}
                            onChange={newValue => {
                              setValue(newValue);
                              setLoader(true);
                            }}
                            renderInput={params => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <hr />
                    </div>
                    {!driverActivity.length ? (<>
                      <div className="alert alert-danger fade show text-center">
                        Driver Activity not yet started
                      </div>
                    </>) : (
                    <>
                      <Timeline
                        sx={{
                          [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.2,
                          },
                        }}
                      >
                        {driverActivity.map((activity, key) => {
                          return (
                            <TimelineItem key={key}>
                              <TimelineOppositeContent color="textSecondary">
                                <span className="mr-10">
                                  {moment(activity.date).format("DD/MM/YYYY")}
                                </span>
                                {moment(activity.date+' '+activity.time).format("hh:mm:ss a")}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot variant="outlined" />
                                { key <= (driverActivity.length - 2) && (
                                  <TimelineConnector />
                                )}
                              </TimelineSeparator>
                              <TimelineContent color={activity.titleTextColor}>
                                {activity.title}
                                {activity.details &&
                                  activity.details.length > 0 && (
                                    <>
                                      {activity.details.map((dt, dtKey) => {
                                        return (
                                          <div key={dtKey}>
                                            <Typography
                                              color={dt.headColor}
                                              sx={{
                                                margin: dt.mt
                                                  ? `${dt.mt}px`
                                                  : "0px",
                                                fontWeight: 800,
                                              }}
                                            >
                                              {dt.head}
                                            </Typography>
                                            {dt.kv && dt.kv.length > 0 && (
                                              <>
                                                {dt.kv.map((v, k) => {
                                                  if (v.key == "image") {
                                                    return (
                                                      <img
                                                        src={v.imageUrl}
                                                        className="rounded avatar-lg m-1"
                                                        key={k}
                                                      />
                                                    )
                                                  } else {
                                                    return (
                                                      <Typography
                                                        key={k}
                                                        style={{
                                                          margin: v.mt
                                                            ? `${v.mt}px`
                                                            : "0px",
                                                        }}
                                                      >
                                                        <label
                                                          className={`form-label ${v.colorClass}`}
                                                        >
                                                          {v.key} {v.saperater}{" "}
                                                        </label>
                                                        {v.geoCode &&
                                                          v.geoCode === true && (
                                                            <GetGoeAddress
                                                              latitude={
                                                                v.latitude
                                                              }
                                                              longitude={
                                                                v.longitude
                                                              }
                                                            />
                                                          )}

                                                        {v.sendNoti &&
                                                          v.sendNoti === true && (
                                                            <>
                                                            <a href="javascript:void(0)" className="notifyDriver" onClick={handleShow} data-tripId={`${activity.tripId}`} data-userId={`${activity.userId}`}>
                                                              {" Send Notification "}
                                                            </a>
                                                            <SendNotificationPopup driverId={activity.userId} tripId={activity.tripId} open={show} onClose={handlePopupClose}/>
                                                          </>
                                                          )}
                                                        {v.callDriver &&
                                                          v.callDriver === true && (
                                                            <a href={`${ v.mobile ? `tel:` + v.mobile : "#" }`} className="">
                                                              {" | Call Driver "}
                                                            </a>
                                                            
                                                          )}

                                                        {/* <GetGoeAddress latitude={v.latitude} longitude={v.longitude} /> */}
                                                        {v.value}
                                                      </Typography>
                                                    )
                                                  }
                                                })}
                                              </>
                                            )}
                                          </div>
                                        )
                                      })}
                                    </>
                                  )}
                              </TimelineContent>
                            </TimelineItem>
                          )
                        })}
                      </Timeline>
                    </>
                    )}
                  </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Activity
