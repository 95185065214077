import React from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'

function NoAccess() {
  return (
    <Row>
        <Col>
            <Card>
                <CardBody>
                    <CardText>
                        You don't have access for this page.
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    </Row>
  )
}

export default NoAccess