import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, IconButton,  } from "@mui/material"
import { GoogleMap } from "../../components/Common/GoogleMap";
import { Row, Col } from "reactstrap";


export const ViewMapPopup = ({ onClose, location, open }) => {
  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": { borderRadius: "10px" },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
            fontWeight: "bold",
            paddingLeft: 3,
          }}
        >
          Scanning Point Map
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
        Latitude: <code>{location.lat}</code>{" "}
        Longitude: <code>{location.lng}</code>
          <Row>
            <Col style={{ minHeight:"382px" }} >
                <GoogleMap location={location} />
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
