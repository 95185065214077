import React, { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"
import Breadcrumb from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import { OperationalAreaAPI } from "apis/OperationalAreaAPI"
import { CountryAPI } from "./../../apis/CountryAPI"
import { UserAPI } from "./../../apis/UserAPI"
import { RegionAPI } from "./../../apis/RegionAPI"

const AddUser = () => {
  const [countries, setCountries] = useState([])
  const [opsAreas, setOpsAreas] = useState([])
  const [regions, setRegions] = useState([])
  const [roles, setRoles] = useState([])
  const { addToast } = useToasts()
  const history = useHistory()

  useEffect(() => {
    CountryAPI.getActiveCountries()
      .then(res =>
        setCountries(
          res.data.map(countries => ({
            label: countries.name,
            value: countries.id,
          }))
        )
      )
      .catch(err => console.log("err", err))

    OperationalAreaAPI.getOperationalAreasByCountry()
      .then(res =>
        setOpsAreas(
          res.data.map(opsAreas => ({
            label: opsAreas.name,
            value: opsAreas.id,
          }))
        )
      )
      .catch(err => console.log("err", err))

    UserAPI.getRoles()
      .then(res =>
        setRoles(
          res.data.map(role => ({
            label: role.name,
            value: role.id,
          }))
        )
      )
      .catch(err => console.log("err", err))

      RegionAPI.getActiveRegions()
      .then(res => {
        if (res) {
          setRegions(res.data.map(reg => ({ label: reg.name, value: reg.id })))
        }
      })
      .catch(err => console.log("err", err))
  }, [])
  const handleSubmit = (values, action) => {
    console.log("values", values)
    UserAPI.addUser(values)
      .then(res => {
        // action.setSubmitting(false);
        if (res) {
          history.push("/users")
          addToast("User has saved successfully", {
            appearance: "success",
            autoDismiss: true,
          })
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.errors) {
          err.response.data.errors.map(error => {
            console.log(error.msg)
            addToast(error.msg, { appearance: "error", autoDismiss: true })
          })
        }
      }).finally(() => {
        action.setSubmitting(false);
      })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      role_id: "",
      country_ids: [],
      operational_area_ids: [],
      region_id: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter operational area name"),
      email: Yup.string().email().required("Please select country"),
      role_id: Yup.string().required("Please select role"),
    }),
    onSubmit: (values, action) => {
      console.log("values", values)
      handleSubmit(values, action)
    },
  });

  // const handleCountryChange = useCallback(
  //   (value) => {
  //     if( value ) {
  //       OperationalAreaAPI.getOperationalAreasByCountry(value)
  //       .then(res =>
  //         setOpsAreas(
  //           res.data.map(opsAreas => ({
  //             label: opsAreas.name,
  //             value: opsAreas.id,
  //           }))
  //         )
  //       )
  //       .catch(err => console.log("err", err))
  //     } else {
  //       setOpsAreas([]);
  //     }
  //   },
  //   []
  // );
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Add User" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <button
                        type="button"
                        className="btn btn-link  "
                        onClick={() => history.goBack()}
                      >
                        <i className="bx bx-arrow-back"></i>
                        <span> Back</span>
                      </button>
                    </Col>
                  </Row>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group">
                      <Row>
                        <div className="col-md-6">
                          <Label className="form-label">User Name</Label>
                          <Input
                            name="name"
                            className="form-control"
                            placeholder=" Enter User Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder=" Enter Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6 mt-3">
                          <Label className="form-label">Role</Label>
                          <Input
                            name="role_id"
                            className="form-control"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role_id || ""}
                            invalid={
                              validation.touched.role_id &&
                              validation.errors.role_id
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Role</option>
                            {roles.map((roles, index) => (
                              <option key={index} value={roles.value}>
                                {roles.label}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.role_id &&
                          validation.errors.role_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.role_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        { validation.values.role_id == 3 && (
                        <div className="col-md-6 mt-3">
                          <Label className="form-label">Country</Label>
                          <Input
                            name="country_ids"
                            className="form-control"
                            type="select"
                            multiple={true}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country_ids || ""}
                            invalid={
                              validation.touched.country_ids &&
                              validation.errors.country_ids
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Countries</option>
                            {countries.map((countries, index) => (
                              <option key={index} value={countries.value}>
                                {countries.label}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.country_ids &&
                          validation.errors.country_ids ? (
                            <FormFeedback type="invalid">
                              {validation.errors.country_ids}
                            </FormFeedback>
                          ) : null}
                        </div>
                        )}
                        { validation.values.role_id == 4 && (
                          <>
                            <div className="col-md-6 mt-3">
                            <Label className="form-label">Operational Area</Label>
                            <Input
                                name="operational_area_ids"
                                className="form-control"
                                type="select"
                                multiple={true}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.operational_area_ids || ""}
                                invalid={
                                validation.touched.operational_area_ids &&
                                validation.errors.operational_area_ids
                                    ? true
                                    : false
                                }
                            >
                                {/* <option value="">Select Operational Area</option> */}
                                {opsAreas.map((opsAreas, index) => (
                                <option key={index} value={opsAreas.value}>
                                    {opsAreas.label}
                                </option>
                                ))}
                            </Input>
                            {validation.touched.operational_area_ids &&
                            validation.errors.operational_area_ids ? (
                                <FormFeedback type="invalid">
                                {validation.errors.operational_area_ids}
                                </FormFeedback>
                            ) : null}
                            </div>
                            </>
                        )}
                        { validation.values.role_id == 6 && (
                          <div className="col-md-6 mt-3">
                            <Label className="form-label">Regions</Label>
                            <Input
                                name="region_ids"
                                className="form-control"
                                type="select"
                                multiple={true}
                                onChange={ validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.region_ids || ""}
                                invalid={
                                validation.touched.region_ids &&
                                validation.errors.region_ids
                                    ? true
                                    : false
                                }
                            >
                                <option value="">Select Region</option>
                                {regions.map((region, index) => (
                                  <option key={index} value={region.value}>
                                    {region.label}
                                  </option>
                                ))}
                            </Input>
                            {validation.touched.region_ids &&
                            validation.errors.region_ids ? (
                                <FormFeedback type="invalid">
                                {validation.errors.region_ids}
                                </FormFeedback>
                            ) : null}
                            </div>
                        )}
                      </Row>
                    </div>
                    <div className="mt-4">
                      <Button disabled={validation.isSubmitting} type="submit" color="primary">
                        Add User
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUser
