import React from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"
import { DatePicker } from "rsuite"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { DriverAPI } from "../../apis/DriverAPI"


// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

function AddEstimatedKms() {
  const params = useParams();
  const history = useHistory()
  const { addToast } = useToasts()
  const [drivingDate, setDrivingDate] = React.useState(new Date())

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        driving_date: drivingDate,
        id: params.driverId
    },
    validationSchema: Yup.object({
        driving_date: Yup.string()
            .trim()
            .required("Please Select Date"),
        estimated_kms: Yup.number()
            .required("Please Enter Estimated KMs")
    }),
    onSubmit: (values, action) => {
        console.log(values, action);
      handelAdd(values, action)
    },
  })

  
  const handelAdd = (value, action) => {
    DriverAPI.AddEstimatedKms(value)
      .then(res => {
        if (res) {
          history.push(`/drivers/${params.driverId}/estimated-kms`)
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.errors) {
          err.response.data.errors.map(error => {
            addToast(error.msg, { appearance: "error", autoDismiss: true })
          })
        }
      })
      .finally(() => {
        action.setSubmitting(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Add Estimated KMs" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <button
                        type="button"
                        className="btn btn-link  "
                        onClick={() => history.goBack()}
                      >
                        <i className="bx bx-arrow-back"></i>
                        <span> Back</span>
                        {/* <span>{props.t("Add Driver")}</span> */}
                      </button>
                      <hr />
                    </Col>
                  </Row>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Label className="form-label">Select Date</Label>
                        <DatePicker
                          name="driving_date"
                          format="dd/MM/yyyy"
                          block
                          oneTap
                          value={validation.values.driving_date}
                          onChange={setDrivingDate}
                        />
                        {validation.touched.driving_date &&
                        validation.errors.driving_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.driving_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <Label className="form-label">Estimated kms</Label>
                        <Input
                          name="estimated_kms"
                          className="form-control"
                          placeholder="Enter Estimated kms"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.estimated_kms || ''}
                        />
                        {validation.touched.estimated_kms &&
                        validation.errors.estimated_kms ? (
                          <FormFeedback type="invalid">
                            {validation.errors.estimated_kms} 
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        disabled={validation.isSubmitting}
                        type="submit"
                        color="primary"
                      >
                        Add Estimated KMs
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddEstimatedKms
