import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
// import { useToasts } from 'react-toast-notifications';
import Loader from "../../components/Common/Loader"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
// import { useSelector } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { AuthAPI } from "../../apis/AuthAPI"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
// import { signInWithGoogle } from "../../services/firebase"
import { auth, googleProvider } from "../../services/firebase"

const Login = props => {
  //meta title
  document.title = "Login | Smart Drive Admin Application";
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      loginUser(values, props.history)
    },
  })

  const loginUser = (value, history) => {
    setLoader(true)
    AuthAPI.login(value)
      .then(res => {
        setLoader(false)
        //Token.saveToken(res.data.user, res.data.token, res.data.settings);
        if (apiRes.status === false) {
          setError(apiRes.message)
        } else {
          localStorage.setItem("authUser", JSON.stringify(res))
          // localStorage.setItem("I18N_LANGUAGE", res.data.user.language);
          history.push("/dashboard")
        }
      })
      .catch(err => {
        console.log(err)
        setError("Invalid Username or Password")
      })
  }

  const signInWithGoogle = () => {
    auth.signInWithPopup(googleProvider).then(res => {
      console.log(res, res.user.displayName, res.accessToken)
      if (res.user) {
        
        const socailLoginData = {
          name: res.user.displayName,
          email: res.user.email,
          credential: res.credential,
          photoURL: res.user.photoURL
        }
        setLoader(true);
        AuthAPI.socialLogin(socailLoginData)
          .then(apiRes => {
            setLoader(false);
            if (apiRes.status === false) {
              setError(apiRes.message)
            } else {
              // console.log(apiRes, "ssssss");
              localStorage.setItem("authUser", JSON.stringify(apiRes))
              props.history.push("/dashboard");
            }
          })
          .catch(err => {
            setError(err.message)
          })
      }
    })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
        {loader ? (
          <Loader layout="loader" />
        ) : (
          <>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to SmartDrive.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      {/* <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div> */}

                      {/* <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div> */}

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      {/* <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div> */}

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={signInWithGoogle}
                            >
                              <i className="mdi mdi-google"></i>
                            </a>
                          </li>
                        </ul>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                      {/* <div className="login-buttons">
                        <button
                          className="login-provider-button"
                          onClick={signInWithGoogle}
                        >
                          <img
                            src="https://img.icons8.com/ios-filled/50/000000/google-logo.png"
                            alt="google icon"
                          />
                          <span> Continue with Google</span>
                        </button>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </>
        )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
