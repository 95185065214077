import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useToasts } from 'react-toast-notifications';
import Breadcrumb from "../../components/Common/Breadcrumb"
import { DriverAPI } from "../../apis/DriverAPI"
import { CountryAPI } from "./../../apis/CountryAPI"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { messages } from "common/data";

function AddDriver() {
  const history = useHistory()
  const [countries, setCountries] = useState([])
  const { addToast } = useToasts();

  useEffect(() => {
    CountryAPI.getActiveCountries()
      .then(res =>
        setCountries(
          res.data.map(countries => ({
            label: countries.name,
            value: countries.id,
          }))
        )
      )
      .catch(err => console.log("err", err))
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: name || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "User Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]*$/, "Allow alphabets and space only", {
          excludeEmptyString: true,
        })
        .required("Please Enter Your User Name"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Email"),
      country_id: Yup.string().required("Please select country"),
    }),
    onSubmit: (values, action) => {
      addDriver(values, action)
    },
  })

  const addDriver = (value, action) => {
    // setLoader(true)
    DriverAPI.addDriver(value)
      .then(res => {
        // setLoader(false)
        //Token.saveToken(res.data.user, res.data.token, res.data.settings);
        console.log(res)
        if (res) {
          history.push("/drivers")
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.errors) {
          err.response.data.errors.map(error => {
            console.log(error.msg)
            addToast(error.msg, { appearance: "error", autoDismiss: true })
          })
        }
        // console.log(err, err.response.data);
      })
      .finally(() => {
        action.setSubmitting(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Add Driver" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <button
                        type="button"
                        className="btn btn-link  "
                        onClick={() => history.goBack()}
                      >
                        <i className="bx bx-arrow-back"></i>
                        <span> Back</span>
                      </button>
                      <hr />
                    </Col>
                  </Row>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Label className="form-label">User Name</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter User Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-md-6 mt-3">
                      <Label className="form-label">Country</Label>
                      <Input
                          name="country_id"
                          className="form-control"
                          type="select"
                          multiple={false}
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.country_id || ""}
                          invalid={
                          validation.touched.country_id &&
                          validation.errors.country_id
                              ? true
                              : false
                          }
                      >
                          <option value="">Select Country</option>
                          {countries.map((countries, index) => (
                            <option key={index} value={countries.value}>
                              {countries.label}
                            </option>
                          ))}
                      </Input>
                      {validation.touched.operational_area_ids &&
                      validation.errors.operational_area_ids ? (
                          <FormFeedback type="invalid">
                          {validation.errors.operational_area_ids}
                          </FormFeedback>
                      ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        disabled={validation.isSubmitting}
                        type="submit"
                        color="primary"
                      >
                        Add Driver
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddDriver
