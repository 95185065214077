import React, { forwardRef } from "react"
import { Card, CardBody, CardTitle, CardText } from "reactstrap"
import { CSVLink } from "react-csv"

const CustomCards = (props, ref) => {
  const mainClass = props.className ? props.className : "col";
  const d = new Date();
  let month = d.getMonth()+1;
  month = (month.toString().length == 1) ? `0${month}`:month;
  const dateT = `${d.getDate()}/${month}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
  return (
    <React.Fragment>
      <Card
        className={mainClass}
        style={{
          marginLeft: "16px",
          borderWidth: "thin",
          borderRadius: "15px",
        }}
      >
        <CardBody>
          <CardTitle
            style={{
              fontSize: "12px",
            }}
          >
            {props.title}
          </CardTitle>
          <CardText style={{ fontSize: " x-large", fontWeight: " bold" }}>
            {props.count}
          </CardText>
          {props.data && (
            <>
              <button
                className="btn-link btn"
                style={{ paddingLeft: 0 }}
                onClick={props.onClick}
              >
                {props.buttonText}
              </button>
              <CSVLink
                style={{ paddingLeft: "0px" }}
                filename={props.title+"-"+dateT}
                className="btn-link btn "
                data={props.data}
                headers={props.header}
                ref={ref}
                target="_blank"
              />
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export const CustomCard = forwardRef(CustomCards)
