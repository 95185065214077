// import dotenv from 'dotenv'
// const dotenv = require('dotenv');
// dotenv.config()

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { AuthAPI } from "../apis/AuthAPI"

firebase.initializeApp({
    apiKey: "AIzaSyAlOIZtF-orsON_uvPb3wHFQW-1W_FNtr0",
    authDomain: "sv-smartdrive.firebaseapp.com",
    projectId: "sv-smartdrive",
    storageBucket: "sv-smartdrive.appspot.com",
    messagingSenderId: "46055783214",
    appId: "1:46055783214:android:af0924defe0092e24458bf",
    measurementId: "",
});


export const auth = firebase.auth()
export const googleProvider = new firebase.auth.GoogleAuthProvider()
