import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"

function Tc() {
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumb title="Dashboard" breadcrumbItem="Terms & Conditions" />
        Please read the following terms and conditions carefully. They govern your access and use of this website. By accessing, browsing or using this website, you agree to be bound by these terms and conditions and accept them in full, as they may be modified by the operators of this website, SmartDrive Corporation (“SmartDrive”) from time‑to‑time as posted on this website. If you do not agree, please exit this website.
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tc
