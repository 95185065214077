import React, { useEffect, useState } from "react"
import { Container, Row, Card, CardBody, Col } from "reactstrap"
import MUIDataTable from "mui-datatables"
import Breadcrumb from "../components/Common/Breadcrumb"
import { CountryAPI } from "../apis/CountryAPI"
import Loader from "../components/Common/Loader"

function CountryLevelPreview(props) {
  document.title = "Country Level Preview | Smart Drive Admin Application"
  const [loader, setLoader] = useState(true)
  const [previewdata, setPreviewdata] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [dateRange, setDateRange] = useState({
    from: new Date(), //moment().subtract(30, "days"),
    to: new Date(),
  })

  const columns = [
    { name: "region.name", label: "Region" },
    { name: "country", label: "Country" },
    { name: "available_cars", label: "Available Cars" },
    { name: "active_cars", label: "Active Cars" },
    {
      name: "active_cars_percent",
      label: "Active Cars %",
      options: {
        customBodyRender: value => {
          return (
            <span className={value > 85 ? "text-success" : "text-danger"}>
              {" "}
              {value}%{" "}
            </span>
          )
        },
      },
    },
    { name: "downtime_percent", label: "Downtime %", options:{
      customBodyRender: value => {
        return (
          <span className={value < 25 ? "text-success" : "text-danger"}>
            {" "}
            {value}%{" "}
          </span>
        )
      },
    }},
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    elevation: 0,
    serverSide: true,
    search: false,
    enableNestedDataAccess: ".",
    page,
    count,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page)
      } else if (action === "changeRowsPerPage") {
        setLimit(tableState.rowsPerPage)
      }
    },
  }

  useEffect(() => {
    const queryStringObject = {
      from: dateRange.from,
      to: dateRange.to,
      limit,
      page,
    }
    CountryAPI.getCountriesSpecificPreview(queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setPreviewdata(res.data.previewData)
        setCount(res.data.total)
      }
    })
  }, [dateRange, page, limit])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Preview" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loader ? (
                    <Loader layout="table" />
                  ) : (
                    <MUIDataTable
                      title={""}
                      data={previewdata}
                      columns={columns}
                      options={options}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CountryLevelPreview
