import React, { useEffect, useState, useCallback } from "react"
import { Container, Row, Card, CardBody, Col, Label, Input } from "reactstrap"
import MUIDataTable from "mui-datatables"
import moment from "moment"
import { DateRangePicker } from "rsuite"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { NotificationAPI } from "../../apis/NotificationAPI"
import { DriverAPI } from "../../apis/DriverAPI"
import Loader from "../../components/Common/Loader"

function Notification(props) {
  document.title = "Notifications | Smart Drive Admin Application";
  const [notifications, setNotifications] = useState([])
  const [drivers, setDrivers] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [driverFilter, setDriverFilter] = useState("all");
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })
  const [loader, setLoader] = useState(true)

  const columns = [
    // { name: "id", label: "#" },
    { name: "title", label: "Title" },
    { name: "trip_id", label: "Trip ID" },
    { name: "trip", label: "Trip ID", options: {display:false} },
    { name: "user.name", label: "Driver", options:
      {
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta, value);
          if(value === undefined && tableMeta.rowData[2] && tableMeta.rowData[2].user){
            return tableMeta.rowData[2].user.name;
          }else{
            return value;
          }
        },
      } 
    },
    { name: "description", label: "Description" },
    { name: "source", label: "Source" },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === 1 ? (
            <span className="font-size-12 badge-soft-success badge bg-secondary">
              Read
            </span>
          ) : (
            <span className="font-size-12 badge-soft-danger badge bg-secondary">
              Unread
            </span>
          )
        },
      },
    },
    { 
      name: "createdAt", 
      label: "Created At",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD/MM/YYYY h:m a")
        },
      },
    },
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    elevation: 0,
    serverSide: true,
    search: false,
    enableNestedDataAccess: ".",
    page,
    count,
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      if (action === "changePage") {
        // console.log("Go to page", tableState.page);
        setPage(tableState.page)
      } else if(action === "changeRowsPerPage") {
        setLimit(tableState.rowsPerPage)
      }
    }
  }

  const handleListDateRangeChange = useCallback(values => {
    setDateRange({
      from: values[0],
      to: values[1],
    })
  }, []);


  const handleDriverOnChnage = ( event => {
    // console.log(event.target.value);
    setDriverFilter(event.target.value)
  })

  useEffect(() => {
    const queryStringObject = {
      from: dateRange.from,
      to: dateRange.to,
      driver: driverFilter, 
      limit,
      page
    }
    NotificationAPI.List(queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setNotifications(res.data.notifications);
        setCount(res.data.total);
      }
    })
  }, [dateRange, driverFilter, page, limit])

  useEffect(() => {
    DriverAPI.getDriversWithoutFilter().then(res => {
      // setLoader(false)
      if (res) {
        setDrivers(res.data)
      }
    });
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Notifications" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                {loader ? (
                    <Loader layout="table" />
                  ) : (
                  <>
                    <Row>
                      <Col md="3">
                      <div className="input-group flex-nowrap">
                        <Label className="col-sm-3 col-form-label">
                          Select Driver
                        </Label> 
                        <Input type={"select"} className="form-control" onChange={handleDriverOnChnage}>
                          <option value="all">All</option>
                          { drivers.map(( driver, k ) => {
                            return (
                              <option key={k} value={driver.id}>{driver.name}</option>
                            )
                          })}
                        </Input>
                        </div>
                      </Col>
                      <Col>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <Label
                            className="mr-2"
                            style={{ marginTop: "5px", marginRight: "11px" }}
                          >
                            Filter By
                          </Label>
                          <DateRangePicker
                            size="sm"
                            placeholder="Select Date Range"
                            character="-"
                            format="dd/MM/yyyy"
                            placement="auto"
                            value={[
                              new Date(dateRange.from),
                              new Date(dateRange.to),
                            ]}
                            onChange={handleListDateRangeChange}
                          />
                        </div>
                      </Col>
                      
                    </Row>
                    <MUIDataTable
                      title={""}
                      data={notifications}
                      columns={columns}
                      options={options}
                    />
                  </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notification
