import React, { useEffect, useState } from "react"
import { Container, Row, Card, CardBody, Col, Button } from "reactstrap"
import MUIDataTable from "mui-datatables"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { RegionAPI } from "../../apis/RegionAPI"
import Loader from "../../components/Common/Loader"
import { Link, useHistory } from "react-router-dom"
import { canPerformAction } from "../../helpers/core_helper"

function RegionsList(props) {
  document.title = "Regions | Smart Drive Admin Application";
  const [loader, setLoader] = useState(true)
  const [resions, setResions] = useState([])
  const history = useHistory()

  const columns = [
    { name: "id", label: "#" },
    { name: "name", label: "Name" },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == 1 ? (
            <span className="font-size-12 badge-soft-success badge bg-secondary">
              Active
            </span>
          ) : (
            <span className="font-size-12 badge-soft-danger badge bg-secondary">
              Inactive
            </span>
          )
        },
      },
    },
  ];
  
  if (canPerformAction("region", "update")) {
    columns.push({
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Button type="button" title="Edit Region" outline color="primary" size="sm" onClick={() =>
                  history.push(`/edit-regions/${value}`)
                }>
                <i className="bx bx-edit align-middle"></i>
              </Button>{' '}
            </>
          )
        },
      },
    });
  }



  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    serverSide: false,
    search: false,
  }



  useEffect(() => {
    RegionAPI.List().then(res => {
      setLoader(false)
      if (res) {
        setResions(res.data.resions)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Regions" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {canPerformAction("region", "add") && (
                        <div>
                          <Link
                            to="/add-region"
                            type="button"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus-circle"></i>
                            <span> Add Region</span>
                            {/* <span>{props.t("Add Driver")}</span> */}
                          </Link>
                        </div>
                      )}
                    </Col>
                  </Row>
                  { loader ? ( <Loader layout="table" /> ) : (
                    <MUIDataTable
                      title={""}
                      data={resions}
                      columns={columns}
                      options={options}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegionsList
