import config from "../config"
import { post, get, put, del } from "../helpers/api_helper"

export const UserAPI = {
  getUsersList: async () => {
    return await get(`${config.API_URL}api/users`)
  },

  addUser: async data => {
    return await post(`${config.API_URL}api/users`, data)
  },

  DeleteUser: async (userId) => {
    return await del(`${config.API_URL}api/users/${userId}`)
  },

  getRoles: async () => {
    return await get(`${config.API_URL}api/roles`)
  },

  getUser: async (id) => {
    return await get(`${config.API_URL}api/users/${id}`)
  },

  updateUser: async (id, data) => {
    return await put(`${config.API_URL}api/users/${id}`, data)
  }, 
}