import config from "../config"
import { post, get, put } from "../helpers/api_helper"

export const OperationalAreaAPI = {
  addOperationalArea: async data => {
    return await post(`${config.API_URL}api/operational-areas`, data)
  },

  getOperationalAreasList: async () => {
    return await get(`${config.API_URL}api/operational-areas`)
  },

  getOperationalAreasByCountry: async () => {
    return await get(`${config.API_URL}api/operational-areas/all-countries`);
  },

  getOperationalArea: async (opaId) => {
    return await get(`${config.API_URL}api/operational-areas/${opaId}`);
  },

  updateOperationalArea: async (opaId, data) => {
    return await put(`${config.API_URL}api/operational-areas/${opaId}`, data);
  }
}