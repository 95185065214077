import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import { Row } from "reactstrap";
import SendNotificationForm from "./SendNotificationForm"

export const SendNotificationPopup = ({ driverId, tripId,  onClose, open }) => {
  return (
    <React.Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": { borderRadius: "10px" },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bold",
            paddingLeft: 3,
          }}
        >
          Send Notification
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Row>
          <SendNotificationForm driverId={driverId} tripId={tripId} onClose={onClose} />
          </Row>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
