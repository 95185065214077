import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Privacy Policy and Terms & conditions
import Privacy from "../pages/Policy/Privacy"
import Tc from "../pages/Policy/Tc"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Report from "../pages/Report/index"

// Driver
import AddDriver from "pages/Driver/AddDriver"
import DriverList from "pages/Driver/DriverList"
import ViewDriver from "pages/Driver/ViewDriver"
import Activity from "pages/Driver/Activity"
import EstimatedKmsList from "pages/Driver/EstimatedKmsList"
import EstimatedKmsAdd from "pages/Driver/AddEstimatedKms"

import Notification from '../pages/Notification/List';
import Regions from '../pages/Regions/List';
import AddRegion from '../pages/Regions/Add';
import EditRegion from '../pages/Regions/Edit';
import Setting from '../pages/Setting/index';

import OperationalArea from '../pages/OperationalArea/List';
import OperationalAreaAdd from '../pages/OperationalArea/Add';
import OperationalAreaEdit from '../pages/OperationalArea/Edit';
import CountriesList from '../pages/Countries/List';
import CountryAdd from '../pages/Countries/Add';
import CountryEdit from '../pages/Countries/Edit';
import Users from "../pages/Users/List"
import AddUser from "../pages/Users/Add"
import EditUser from "../pages/Users/Edit"
import CountryLevelPreview from "../pages/CountryLevelPreview"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/drivers", component: DriverList },
  { path: "/add-driver", component: AddDriver },
  { path: "/view-driver/:driverId", component: ViewDriver },
  { path: "/driver/activity/:driverId", component: Activity },
  { path: "/drivers/:driverId/estimated-kms", component: EstimatedKmsList },
  { path: "/drivers/:driverId/add-estimated-kms", component: EstimatedKmsAdd },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/notifications", component: Notification },
  { path: "/regions", component: Regions },
  { path: "/add-region", component: AddRegion },
  { path: "/edit-regions/:regionId", component: EditRegion },
  { path: "/countries", component: CountriesList },
  { path: "/add-countries", component: CountryAdd },
  { path: "/edit-countries/:countryId", component: CountryEdit },
  { path: "/operational-areas", component: OperationalArea },
  { path: "/add-operational-area", component: OperationalAreaAdd },
  { path: "/edit-operational-areas/:opaId", component: OperationalAreaEdit },
  { path: "/reports", component: Report },
  { path: "/settings", component: Setting },
  { path: "/users", component: Users },
  { path: "/add-users", component: AddUser },
  { path: "/edit-users/:userId", component: EditUser },
  { path: "/country-lavel-preview", component: CountryLevelPreview },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  { path: "/privacy-policy", component: Privacy },
  { path: "/terms-and-conditions", component: Tc },
]

export { publicRoutes, authProtectedRoutes }
