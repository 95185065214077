import config from "../config"
import { post, get, put } from "../helpers/api_helper"

export const RegionAPI = {
  addRegion: async data => {
    return await post(`${config.API_URL}api/regions`, data)
  },

  List: async () => {
    return await get(`${config.API_URL}api/regions`)
  },

  getActiveRegions: async () => {
    return await get(`${config.API_URL}api/regions/active`)
  },

  getRegion: async (regionId) => {
    return await get(`${config.API_URL}api/regions/${regionId}`)
  },

  updateRegion: async (regionId, data) => {
    return await put(`${config.API_URL}api/regions/${regionId}`, data)
  }


}
