import React, { useState, useEffect, useCallback, useLayoutEffect } from "react"
import { Container, Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import moment from "moment"
import MUIDataTable from "mui-datatables"
import { Link, useHistory, useParams } from "react-router-dom"
import { DateRangePicker } from "rsuite"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import { DriverAPI } from "../../apis/DriverAPI"
import { canPerformAction } from "../../helpers/core_helper"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

function EstimatedKmsList(props) {
  document.title = "Drivers Estimated KMs | Smart Drive Admin Application"
  const params = useParams()
  const [loader, setLoader] = useState(true)
  const [delFlag, setDelFlag] = useState()
  const [drivers, setDrivers] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [driverId, setDriverId] = useState()
  const [viewNofiPopup, setViewNofiPopup] = useState(false)
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })

  const history = useHistory()

  useEffect(() => {
    setLoader(true)
    const queryStringObject = {
      limit,
      page,
    }
    DriverAPI.EstimatedKMsList(params.driverId, queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setDrivers(res.data.ek)
        setCount(res.data.total)
      }
    })
  }, [page, limit])

  const columns = [
    {
      name: "driving_date",
      label: "Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("YYYY-MM-DD")
        },
      },
    },
    { name: "estimated_kms", label: "Estimated KMs" },
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    serverSide: true,
    search: false,
    page,
    count,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page)
      } else if (action === "changeRowsPerPage") {
        setLimit(tableState.rowsPerPage)
      }
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Estimated KMs List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loader ? (
                    <Loader layout="table" />
                  ) : (
                    <>
                      <Row>
                        <Col>
                          <button
                            type="button"
                            className="btn btn-link  "
                            onClick={() => history.goBack()}
                          >
                            <i className="bx bx-arrow-back"></i>
                            <span> Back</span>
                          </button>
                        </Col>
                        <Col>
                          {canPerformAction("driver", "estimated_kms") && (
                            <div
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <Link
                                to={`/drivers/${params.driverId}/add-estimated-kms`}
                                type="button"
                                className="btn btn-primary"
                              >
                                <i className="bx bx-plus-circle"></i>
                                <span> Add </span>
                              </Link>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <MUIDataTable
                        title={""}
                        data={drivers}
                        columns={columns}
                        options={options}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EstimatedKmsList
