import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import { DriverAPI } from "../../apis/DriverAPI"
import { getUserRole, canPerformAction } from "../../helpers/core_helper"
import Breadcrumb from "../../components/Common/Breadcrumb"
import DashboardCards from "./DashboardCards"
import NoAccess from "components/Common/NoAccess"
import { SendNotificationPopup } from "../../components/Notification/SendNotificationPopup"
// import { Divider } from "@mui/material"

function Dashboard(props) {
  document.title = "Dashboard | Smart Drive Admin Application"
  const [drivers, setDrivers] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [driverId, setDriverId] = useState()
  const [viewNofiPopup, setViewNofiPopup] = useState(false)
  const [dateRange, setDateRange] = useState({
    from: new Date(),
    to: new Date(),
  })
  const history = useHistory()
  const userRole = getUserRole()
  const canShowPage = canPerformAction("dashboard", "view")

  useEffect(() => {
    // setLoader(true)
    const queryStringObject = {
      from: dateRange.from,
      to: dateRange.to,
      limit,
      page,
    }
    DriverAPI.driverList(queryStringObject).then(res => {
      // setLoader(false)
      if (res) {
        setDrivers(res.data.drivers)
        setCount(res.data.total)
      }
    })
    return () => {
      window.onbeforeunload = () => {
        localStorage.removeItem("driverListFilters")
      }
    }
  }, [dateRange, page, limit])

  const handleSendNotificationClose = () => {
    setViewNofiPopup(false)
  }

  const columns = [
    { name: "id", label: "Driver ID" },
    { name: "name", label: "Name" },
    {
      name: "current_status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta);
          // if(tableMeta.rowData[4]){
          //   console.log(tableMeta.rowData[4]);
          // }
          if (value) {

            return value
            
          }
          else {
            
            return 'NA';
          }
        },
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* <button
                className="btn-link btn btn-"
                onClick={() =>
                  history.push(`/view-driver/${tableMeta.rowData[0]}`)
                }
              >
                View Profile
              </button> */}
              <button
                className="btn-link btn btn-"
                onClick={() => {
                  setViewNofiPopup(true)
                  setDriverId(tableMeta.rowData[0])
                }}
              >
                Send Notification
              </button>
              <button
                className="btn-link btn btn-"
                onClick={() =>
                  history.push(`/driver/activity/${tableMeta.rowData[0]}`)
                }
              >
                View Activities
              </button>
              {/* <a
                href={`${
                  tableMeta.rowData[4]
                    ? `tel:` + tableMeta.rowData[4].mobile
                    : "#"
                }`}
                className="btn-link btn btn-"
                // onClick={() => console.log(value, tableMeta)}
              >
                Connect To Driver
              </a> */}
            </>
          )
        },
      },
    },
    {
      name: "driver",
      label: "",
      options: {
        display: false,
      },
    },
  ]

  const options = {
    selectableRows: "none",
    download: false,
    display: false,
    sort: false,
    empty: false,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPage: limit,
    rowsPerPageOptions: [10, 25, 50, 100],
    downloadOptions: { filename: "driverList.csv", separator: "," },
    elevation: 0,
    serverSide: true,
    search: false,
    page,
    count,
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      if (action === "changePage") {
        // console.log("Go to page", tableState.page);
        setPage(tableState.page)
      } else if (action === "changeRowsPerPage") {
        setLimit(tableState.rowsPerPage)
      }
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="" breadcrumbItem="Dashboard" />
          {canShowPage ? (
            <>
              <DashboardCards />
              {[4, 6, 3].includes(userRole) && (
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <MUIDataTable
                          title={""}
                          data={drivers}
                          columns={columns}
                          options={options}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          ): (
            <NoAccess/>
          )}
        </Container>
        <SendNotificationPopup
          driverId={driverId}
          open={viewNofiPopup}
          onClose={handleSendNotificationClose}
        />
      </div>
    </React.Fragment>
  )
}

export default Dashboard
