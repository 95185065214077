import React from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

export const MapContainer = props => {
  const style = {
    position: "relative",
    width: "97%",
    height: "100%",
  }
  return (
    <Map
      initialCenter={props.location}
      style={style}
      google={window.google}
      zoom={15}
    >
      <Marker
        position={props.location}
      />
    </Map>
  )
}

export const GoogleMap = GoogleApiWrapper({
  apiKey: "AIzaSyAlOIZtF-orsON_uvPb3wHFQW-1W_FNtr0",
})(MapContainer)
