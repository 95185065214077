import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { RegionAPI } from "../../apis/RegionAPI"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CountryAPI } from "./../../apis/CountryAPI"

const AddCountries = () => {
  const [regions, setRegions] = useState([])
  const history = useHistory()
  const { addToast } = useToasts()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      region_id: "",
      status: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter country name"),
      region_id: Yup.string().required("Please select region"),
      status: Yup.string().required("Please select status"),
    }),
    onSubmit: (values, action) => {
      console.log("values", values)
      handleSubmit(values, action)
    },
  })

  useEffect(() => {
    RegionAPI.getActiveRegions().then(res => {
      if (res) {
        setRegions(
          res.data.map(reg => ({ label: reg.name, value: reg.id }))
        )
      }
    })
  }, [])

  const handleSubmit = (value, action) => {
    CountryAPI.addCountries(value)
      .then(res => {
        if (res) {
          history.push("/countries")
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.errors) {
          err.response.data.errors.map(error => {
            console.log(error.msg)
            addToast(error.msg, { appearance: "error", autoDismiss: true })
          })
        }
      }).finally(() => {
        action.setSubmitting(false);
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Add Countries" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <button
                        type="button"
                        className="btn btn-link  "
                        onClick={() => history.goBack()}
                      >
                        <i className="bx bx-arrow-back"></i>
                        <span> Back</span>
                      </button>
                    </Col>
                  </Row>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group row">
                      <div className="col-md-6">
                        <Label className="form-label">Country Name</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Country Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <Label className="form-label">Status</Label>
                        <Input
                          name="status"
                          className="form-control"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status || ""}
                          invalid={
                            validation.touched.status &&
                            validation.errors.status
                              ? true
                              : false
                          }
                        >
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </Input>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="col-md-6 mt-3">
                        <Label className="form-label">Region</Label>
                        <Input
                          name="region_id"
                          className="form-control"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.region_id || ""}
                          invalid={
                            validation.touched.region_id &&
                            validation.errors.region_id
                              ? true
                              : false
                          }
                        >
                            <option  value="">Select Region</option>
                          {regions.map((reg, index) => (
                            <option key={index} value={reg.value}>
                              {reg.label}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.region_id &&
                        validation.errors.region_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.region_id}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button disabled={validation.isSubmitting} type="submit" color="primary">
                        Add Country
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCountries
