import config from "../config"
import { post, get, put } from "../helpers/api_helper"

export const CountryAPI = {
  addCountries: async data => {
    console.log(data, "this is data")
    return await post(`${config.API_URL}api/countries`, data)
  },

  getCountriesList: async () => {
    return await get(`${config.API_URL}api/countries`)
  },

  getActiveCountries: async () => {
    return await get(`${config.API_URL}api/countries/active`)
  },

  getUserSpecificCountries: async () => {
    return await get(`${config.API_URL}api/countries/specific`)
  },

  getRegionSpecificCountries: async (regionId) => {
    return await get(`${config.API_URL}api/countries/region/${regionId}`)
  },

  updateCountry: async (countryId, data) => {
    return await put(`${config.API_URL}api/countries/${countryId}`, data)
  },
  getCountry: async countryId => {
    return await get(`${config.API_URL}api/countries/${countryId}`)
  },

  getCountriesSpecificPreview: async (data) => {
    return await get(`${config.API_URL}api/preview?from=${data.from}&to=${data.to}&limit=${data.limit}&page=${data.page}`)
  },

  getCountriesSpecificDash: async (data) => {
    return await get(`${config.API_URL}api/preview/dash?from=${data.from}&to=${data.to}&region=${data.region}&country=${data.country}`)
  }
}
