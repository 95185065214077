import React, { useCallback, useState, useRef } from "react"
import { Container, Row, Col, Label } from "reactstrap"
import { DateRangePicker } from "rsuite"
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import moment from "moment"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { CustomCard } from "../../components/Driver/CustomCard"
import { ReportAPI } from "../../apis/ReportAPI"

function Report() {
  document.title = "Reports | Smart Drive Admin Application";
  const loginReportRef = useRef(0)
  // const attendanceReportRef = useRef(0)
  const kmDrivenReportRef = useRef(0)
  const targetKmDrivenReportRef = useRef(0)
  const timeInBreakReportRef = useRef(0)
  const timeProductionReportRef = useRef(0)
  const fuleRefillReportRef = useRef(0)
  const accidentIncidentReportRef = useRef(0)
  const masterReportRef = useRef(0)
  const [loginHrsReport, setLoginHrsReport] = useState([])
  // const [dailyAttendanceReport, setDailyAttendanceReport] = useState([])
  const [drivenKMReport, setDrivenKMReport] = useState([])
  const [targetDrivenKMReport, setTargetDrivenKMReport] = useState([])
  const [timeInBreakReport, setTimeInBreakReport] = useState([])
  const [timeProductionReport, setTimeProductionReport] = useState([])
  const [fuleRefillReport, setFuleRefillReport] = useState([])
  const [accidentIncidentReport, setAccidentIncidentReport] = useState([])
  const [masterReport, setMasterReport] = useState([])

  const [dateRange, setRateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })

  const handleDateRangeChange = useCallback(values => {
    setRateRange({
      from: values[0],
      to: values[1],
    })
  }, [])

  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
  ];

  const headers = [
    { label: "Sr.", key: "Sr" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Attendance mark time", key: "login_time" },
    { label: "Trip ID", key: "trip_id" },
    { label: "Drive Start Date", key: "start_drive_date" },
    { label: "Drive Start Time", key: "start_drive_time" },
    { label: "Scan Start Date", key: "start_scan_date" },
    { label: "Scan Start Time", key: "start_scan_time" },
    { label: "Scan End Date", key: "stop_scan_date" },
    { label: "Scan End Time", key: "stop_scan_time" },
    { label: "Drive End Date", key: "stop_drive_date" },
    { label: "Drive End Time", key: "stop_drive_time" },
    { label: "Attendance close time", key: "logout_time" },
    { label: "Auto Force Logout", key: "is_closed_from_cron" },
    { label: "Total Attendance time(in hrs)", key: "total_login_time" },
  ]

  const headersDrivenKM = [
    { label: "Sr.", key: "Sr" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Day start odometer reading", key: "start_om_reading" },
    { label: "Day end odometer reading", key: "end_om_reading" },
    { label: "Total KM driven", key: "total_km_driven" },
    { label: "Date", key: "date" },
  ]

  const headersTargetDrivenKM = [
    { label: "Sr.", key: "Sr" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Day start Scan odometer reading", key: "start_om_reading" },
    { label: "Day end Scan odometer reading", key: "end_om_reading" },
    { label: "Total KM driven", key: "total_km_driven" },
    { label: "Date", key: "date" },
  ]

  const headersTimeInBreak = [
    { label: "Sr.", key: "Sr" },
    { label: "Trip ID", key: "trip_id" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Total Break Time( In Minutes )", key: "total_pause_time" },
    { label: "Date", key: "date" }
  ]

  const headersTimeProduction = [
    { label: "Sr.", key: "Sr" },
    { label: "Trip Id", key: "trip_id" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Spare SSD Count", key: "ssd_count" },
    { label: "Total Production Time (In Minutes)", key: "total_productive_time" },
    { label: "Date", key: "date" }
  ]

  const headersFuleRefill = [
    { label: "Sr.", key: "Sr" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "Created By", key: "created_by" },
    { label: "Role", key: "role" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Total fuel refill in a week (in gallons)", key: "total_fule_refill" },
    { label: "Week start date", key: "week_start_date" },
    { label: "Week end date", key: "week_end_date" },
  ]

  const headersAccidentIncident = [
    { label: "Sr.", key: "Sr" },
    { label: "Trip Id", key: "trip_id" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Scanning stopped count (Incident)", key: "incident_count" },
    { label: "Scanning stopped count (Accident)", key: "accident_count" },
    { label: "Total stopped count", key: "total_stopped_count" },
    { label: "Date", key: "date" }
  ]

  const headersMaster = [
    { label: "Sr.", key: "Sr" },
    { label: "Trip Id", key: "trip_id" },
    { label: "Driver Name", key: "driver_name" },
    { label: "Driver Email", key: "driver_email" },
    { label: "Country", key: "country" },
    { label: "OPS Lead", key: "opsLead" },
    { label: "Country Lead", key: "countryLead" },
    { label: "Vehicle Id", key: "vehicle_id" },
    { label: "Spare SSD Count", key: "ssd_count" },
    { label: "Day start odometer reading", key: "start_om_reading" },
    { label: "Day end odometer reading", key: "end_om_reading" },
    { label: "Total KM driven", key: "total_km_driven" },
    { label: "Day start Scan odometer reading", key: "start_om_reading" },
    { label: "Day end Scan odometer reading", key: "end_om_reading" },
    { label: "Target KM driven", key: "target_km_driven" },
    { label: "Total Break Time( In Minutes )", key: "total_pause_time" },
    { label: "Total Production Time (In Minutes)", key: "total_productive_time" },
    { label: "Date", key: "date" },
  ]

  const handleDownloadLoginReport = () => {
    ReportAPI.getReport(dateRange, "login-hours").then(res => {
      setLoginHrsReport(res.data)
      setTimeout(() => {
        loginReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadDrivenKMReport = () => {
    ReportAPI.getReport(dateRange, "km-driven").then(res => {
      setDrivenKMReport(res.data)
      setTimeout(() => {
        kmDrivenReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadTargetDrivenKMReport = () => {
    ReportAPI.getReport(dateRange, "target-km-driven").then(res => {
      setTargetDrivenKMReport(res.data)
      setTimeout(() => {
        targetKmDrivenReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadTimeInBreakReport = () => {
    ReportAPI.getReport(dateRange, "time-in-break").then(res => {
      setTimeInBreakReport(res.data)
      setTimeout(() => {
        timeInBreakReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadTimeProductionReport = () => {
    ReportAPI.getReport(dateRange, "time-production").then(res => {
      setTimeProductionReport(res.data)
      setTimeout(() => {
        timeProductionReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadFuleRefillReport = () => {
    ReportAPI.getReport(dateRange, "fule-refill").then(res => {
      setFuleRefillReport(res.data)
      setTimeout(() => {
        fuleRefillReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadMasterReport = () => {
    ReportAPI.getReport(dateRange, "master").then(res => {
      setMasterReport(res.data)
      setTimeout(() => {
        masterReportRef.current.link.click()
      }, 900)
    })
  }

  const handleDownloadAccidentIncidentReport = () => {
    ReportAPI.getReport(dateRange, "accident-incident").then(res => {
      setAccidentIncidentReport(res.data)
      setTimeout(() => {
        accidentIncidentReportRef.current.link.click()
      }, 900)
    })
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Dashboard" breadcrumbItem="All Reports" />
        <Row>
          <div className="row">
            <Col>
              <div
                className="mb-2"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Label
                  className="mr-2"
                  style={{ marginTop: "5px", marginRight: "11px" }}
                >
                  Filter By
                </Label>
                <DateRangePicker
                  ranges={predefinedRanges}
                  size="sm"
                  placeholder="Select Date Range"
                  character="-"
                  format="dd/MM/yyyy"
                  placement="auto"
                  value={[
                    new Date(dateRange.from),
                    new Date(dateRange.to),
                  ]}
                  onChange={handleDateRangeChange}
                />
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="row ">
            {/* <CustomCard
              title={"Daily Attendance Report"}
              buttonText={"Download Report"}
              data={dailyAttendanceReport}
              onClick={handleDownloadAttendanceReport}
              header={headersAttendanceReport}
              ref={attendanceReportRef}
            /> */}
            <CustomCard
              title={"Daily Attendance & Login Hours"}
              buttonText={"Download Report"}
              header={headers}
              data={loginHrsReport}
              onClick={handleDownloadLoginReport}
              ref={loginReportRef}
            />
            <CustomCard
              title={"Total KM Driven Report"}
              buttonText={"Download Report"}
              header={headersDrivenKM}
              data={drivenKMReport}
              onClick={handleDownloadDrivenKMReport}
              ref={kmDrivenReportRef}
            />
            <CustomCard
              title={"Target KM Driven Report"}
              buttonText={"Download Report"}
              header={headersTargetDrivenKM}
              data={targetDrivenKMReport}
              onClick={handleDownloadTargetDrivenKMReport}
              ref={targetKmDrivenReportRef}
            />
            <CustomCard
              title={"Time In Break Report"}
              buttonText={"Download Report"}
              data={timeInBreakReport}
              onClick={handleDownloadTimeInBreakReport}
              header={headersTimeInBreak}
              ref={timeInBreakReportRef}
            />
          </div>
          <div className="row ">
            <CustomCard
              title={"Time Production Report"}
              buttonText={"Download Report"}
              data={timeProductionReport}
              onClick={handleDownloadTimeProductionReport}
              header={headersTimeProduction}
              ref={timeProductionReportRef}
            />
            <CustomCard
              title={"Fuel Refill Report"}
              buttonText={"Download Report"}
              data={fuleRefillReport}
              onClick={handleDownloadFuleRefillReport}
              header={headersFuleRefill}
              ref={fuleRefillReportRef}
            />
            <CustomCard
              title={"Accident/Incident Report"}
              buttonText={"Download Report"}
              data={accidentIncidentReport}
              onClick={handleDownloadAccidentIncidentReport}
              header={headersAccidentIncident}
              ref={accidentIncidentReportRef}
            />
            <CustomCard
              title={"Master Report"}
              buttonText={"Download Report"}
              data={masterReport}
              onClick={handleDownloadMasterReport}
              header={headersMaster}
              ref={masterReportRef}
            />
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Report
