import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"

//Import images
import avatar3 from "../../../assets/images/users/avatar.png"

//i18n
import { withTranslation } from "react-i18next"

import { NotificationAPI } from "apis/NotificationAPI"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [read, setRead] = useState(false);
  const [notifications, setNotifications] = useState([])
  const [notiCount, setNotiCount] = useState(0)

  useEffect(() => {
    NotificationAPI.getBellNotifications().then(res => {
      if (res) {
        setNotifications(res.data.notifications)
        setNotiCount(res.data.unread)
      }
    })
    setRead(false);
  }, [read])

  const readNotificationHandler = (id) => {
    NotificationAPI.markAsReadNotification(id).then(res => {
      if(res && res.status === true) {
        setRead(true);
      }
    });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {notiCount > 0 && (
            <span className="badge bg-danger rounded-pill">{notiCount}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/notifications" className="small">
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.length > 0 &&
              notifications.map((notification, key) => {
                return (
                  <span
                    to="/notifications"
                    className="text-reset notification-item"
                    key={key}
                  >
                    <div className="d-flex">
                      <img
                        src={notification.trip && notification.trip.user && notification.trip.user.avatar ? notification.trip.user.avatar : avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-grow-1">
                        <Row>
                          <Col>
                          <h6 className="mt-0 mb-1">{notification.title}</h6>
                          </Col>
                          { notification.status === 0 && (
                            <Col>
                              <div style={{ display: "flex", justifyContent: "end" }}>
                                <button className="btn btn-link" type="button" onClick={() => readNotificationHandler(notification.id)}><i className="mdi mdi-comment-eye" /></button>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {notification.description} {notification.trip ? `- ${notification.trip.user?.name}` : ''}
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" style={{marginRight:"3px"}} />
                            { moment(notification.createdAt).fromNow() }
                          </p>
                        </div>
                      </div>
                    </div>
                  </span>
                )
              })}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <Link to="/notifications"  key="t-view-more">{props.t("View More..")}</Link>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
