import React, { useState, useEffect, useCallback } from "react"
import "./dashboard.scss";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Label,
  Input,
} from "reactstrap"
import moment from "moment"
import { DateRangePicker } from "rsuite"
import Loader from "../../components/Common/Loader"
import { CountryAPI } from "../../apis/CountryAPI"
import { RegionAPI } from "../../apis/RegionAPI"
import { canPerformAction, getUserRole } from "../../helpers/core_helper"



const DashboardCards = () => {
  const [loader, setLoader] = useState(true)
  const [countries, setCountries] = useState([])
  const [dashData, setDashData] = useState({
    available_cars: 0,
    active_cars: 0,
    active_cars_per: 0,
    total_accidents_reported: 0,
    total_incident_reported: 0,
    total_estimated_kms: 0,
    actual_kms: 0,
    estimated_km_per: 0,
    total_down_time: 0,
    total_productive_time: 0,
    down_time_per: 0,
    total_daily_hours: 0
  })
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, "days"),
    to: new Date(),
  })
  const [regions, setRegions] = useState([])
  const [region, setRegion] = useState("")
  const [regionCountry, setRegionCountry] = useState([])
  const [country, setCountry] = useState("")

  const handleListDateRangeChange = useCallback(values => {
    setDateRange({
      from: values[0],
      to: values[1],
    })
  }, [])

  const handleRegionChange = useCallback(e => {
    const regionId = e.currentTarget.value
    setRegion(regionId)
    setCountry('')
    CountryAPI.getRegionSpecificCountries(regionId).then(res => {
      if (res) {
        setRegionCountry(res.data)
      }
    })

  }, [])

  const handleCountryChange = useCallback(e => {
    const countryId = e.currentTarget.value
    setCountry(countryId)
  }, [])

  const handleRegionCountryChange = useCallback(e => {
    const countryId = e.currentTarget.value
    setCountry(countryId)
  }, [])

  useEffect(() => {
    setLoader(true);
    const queryStringObject = {
      from: dateRange.from,
      to: dateRange.to,
      region: region,
      country: country
    }
    CountryAPI.getCountriesSpecificDash(queryStringObject).then(res => {
      setLoader(false)
      if (res) {
        setDashData(res.data)
      }
    })
    CountryAPI.getUserSpecificCountries().then(res => {
      if (res) {
        setCountries(res.data)
      }
    })
  }, [dateRange, region, country])

  useEffect(() => {
    RegionAPI.getActiveRegions().then(res => {
      if (res) {
        setRegions(res.data.map(reg => ({ label: reg.name, value: reg.id })))
      }
    })
  }, [])
  return (
    <React.Fragment>
      
          <Row>
            { [1, 5, 6].includes(getUserRole()) && (
              <Col md="3">
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <Label
                    className="mr-2"
                    style={{ marginTop: "5px", marginRight: "11px" }}
                  >
                    Region:
                  </Label>
                  <Input
                    type="select"
                    id="exampleSelect"
                    name="region_id"
                    onChange={handleRegionChange}
                  >
                    <option value="">ALL Region</option>
                    {regions.map((reg, index) => (
                      <option key={index} value={reg.value}>
                        {reg.label}
                      </option>
                    ))}
                  </Input>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <Label
                    className="mr-2"
                    style={{ marginTop: "5px", marginRight: "11px" }}
                  >
                    Country:
                  </Label>
                  <Input
                    type="select"
                    id="exampleSelect"
                    name="region_country_id"
                    onChange={handleRegionCountryChange}
                  >
                    <option value="">ALL Countries</option>
                    {regionCountry.map((reg, index) => (
                      <option key={index} value={reg.id}>
                        {reg.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
              
            )}
            { [3].includes(getUserRole()) && (
              <Col md="3">
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <Label
                    className="mr-2"
                    style={{ marginTop: "5px", marginRight: "11px" }}
                  >
                    Location:
                  </Label>
                  <Input
                    type="select"
                    id="exampleSelect"
                    name="country_id"
                    onChange={handleCountryChange}
                  >
                    <option value="">All Countries</option>
                    {countries.map((reg, index) => (
                      <option key={index} value={reg.id}>
                        {reg.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
            )}
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                <Label
                  className="mr-2"
                  style={{ marginTop: "5px", marginRight: "11px" }}
                >
                  Filter By
                </Label>
                <DateRangePicker
                  size="sm"
                  placeholder="Select Date Range"
                  character="-"
                  format="dd/MM/yyyy"
                  placement="auto"
                  value={[new Date(dateRange.from), new Date(dateRange.to)]}
                  onChange={handleListDateRangeChange}
                  className=""
                />
              </div>
            </Col>
          </Row>
          {loader ? (
        <Card> <Loader layout="table" /> </Card>
      ) : (
        <>
          <Row>
            <Col md="3">
              <Card
                style={{
                  borderWidth: "thin",
                  borderRadius: "15px",
                }}
              >
                <CardBody>
                  <CardTitle
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Available Cars
                  </CardTitle>
                  <CardText
                    style={{ fontSize: " x-large", fontWeight: " bold" }}
                  >
                    {dashData.available_cars}
                  </CardText>
                  {/* <button
                    className="btn-link btn set-right"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      console.log("Clicked: View Car VID's ")
                    }}
                  >
                    View Car VID's
                  </button> */}
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card
                style={{
                  borderWidth: "thin",
                  borderRadius: "15px",
                }}
              >
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Active Cars
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.active_cars}
                      </CardText>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Active Cars%
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.active_cars_per}%
                      </CardText>
                    </Col>
                  </Row>
                  {/* <button
                    className="btn-link btn set-right"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      console.log("Clicked: View Car VID's ")
                    }}
                  >
                    View Car VID's
                  </button> */}
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card
                style={{
                  borderWidth: "thin",
                  borderRadius: "15px",
                }}
              >
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Estimated KM's
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_estimated_kms}
                      </CardText>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Actual KM's
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.actual_kms}
                      </CardText>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        KM Coverage%
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.estimated_km_per}%
                      </CardText>
                    </Col>
                  </Row>
                  {/* <button
                    className="btn-link btn set-right"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      console.log("Clicked: View List ")
                    }}
                  >
                    View List
                  </button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              <Card
                style={{
                  borderWidth: "thin",
                  borderRadius: "15px",
                }}
              >
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Login Attendance/Daily Hours
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_daily_hours} hrs
                      </CardText>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Productive Hours
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_productive_time} hrs
                      </CardText>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Downtime Hours
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_down_time} hrs
                      </CardText>
                    </Col>

                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Downtime Hours%
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.down_time_per}%
                      </CardText>
                    </Col>
                  </Row>
                  {/* <button
                    className="btn-link btn set-right"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      console.log("Clicked: View Car VID's ")
                    }}
                  >
                    View Car VID's
                  </button> */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  borderWidth: "thin",
                  borderRadius: "15px",
                }}
              >
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Total Accidents Reported
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_accidents_reported}
                      </CardText>
                    </Col>
                    <Col>
                      <CardTitle
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Total Incidents Reported
                      </CardTitle>
                      <CardText
                        style={{ fontSize: " x-large", fontWeight: " bold" }}
                      >
                        {dashData.total_incident_reported}
                      </CardText>
                    </Col>
                  </Row>
                  {/* <button
                    className="btn-link btn set-right"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      console.log("Clicked: View Car VID's ")
                    }}
                  >
                    View Car VID's
                  </button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default DashboardCards
