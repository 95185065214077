import config from "../config";
import { post } from "../helpers/api_helper";


export const AuthAPI = {
    login: async (data) => {
        return await post(`${config.API_URL}api/auth/signin`, data);
    },
    socialLogin: async (data) => {
        return await post(`${config.API_URL}api/auth/socialSignin`, data);
    },
    // forgotPassword: async (data) => {
    //     return await axios.post(`${config.API_URL}/api/admin/forgot-password`, data);
    // }
}
    