import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { DriverAPI } from "../../apis/DriverAPI"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { SendNotificationPopup } from "../../components/Notification/SendNotificationPopup"
import Loader from "../../components/Common/Loader"

function ViewDriver() {
  const [driver, setDriver] = useState({})
  const [loader, setLoader] = useState(true)
  const [state, setstate] = useState({})
  const [viewNofiPopup, setViewNofiPopup] = useState(false)
  const { addToast } = useToasts()
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    DriverAPI.getDriver(params.driverId)
      .then(res => {
        setLoader(false)
        //Token.saveToken(res.data.user, res.data.token, res.data.settings);
        if (res.data) {
          if (res.data.is_active) {
            setDriver(res.data)
          }
          setstate(res.data.is_approved)
          //history.push("/drivers");
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleApproveProfile = () => {
    const data = {
      driver_id: params.driverId,
      is_approved: 1,
    }
    DriverAPI.changeDriverApproval(data)
      .then(res => {
        setstate(1)
        addToast("Driver Profile Approved Successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDisapproveProfile = () => {
    const data = {
      driver_id: params.driverId,
      is_approved: 2,
    }
    DriverAPI.changeDriverApproval(data)
      .then(res => {
        setstate(2)
        addToast("Driver Profile Disapproved Successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSendNotification = () => {
    setViewNofiPopup(true)
  }

  const handleSendNotificationClose = () => {
    setViewNofiPopup(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="View Driver" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      <Row className="mb-2">
                        <Col>
                          <button
                            type="button"
                            className="btn btn-link  "
                            onClick={() => history.goBack()}
                          >
                            <i className="bx bx-arrow-back"></i>
                            <span> Back</span>
                            {/* <span>{props.t("Add Driver")}</span> */}
                          </button>
                        </Col>
                      </Row>
                      {driver.id ? (
                        <>
                          <div className="row">
                            <div className="mb-4 h4 card-title">
                              Driver Profile Details
                            </div>
                            <hr />
                          </div>
                          <div className="row mb-4">
                            <div className="mb-4">Personal Details</div>
                            <div className="row">
                              <dd className="col-2">First Name</dd>
                              <dt className="col-2">{driver.first_name}</dt>
                              <dd className="col-2">Last Name</dd>
                              <dt className="col-2">{driver.last_name}</dt>
                            </div>
                            <div className="row">
                              <dd className="col-2">Mobile No.</dd>
                              <dt className="col-2">{driver.mobile_country_code} - {driver.mobile}</dt>
                              <dd className="col-2">ICE No.</dd>
                              <dt className="col-2">{driver.emergency_country_code} - {driver.emergency_mobile}</dt>
                            </div>
                            <div className="row">
                              <dd className="col-2">Preferred Language</dd>
                              <dt className="col-2">{driver.prefLang}</dt>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="row">
                              { driver.insurance_img && (
                                <>
                                  <dd className="col-2">Insurance Details</dd>
                                  <dt className="col-2">
                                    <img
                                      className="rounded me-2"
                                      alt="200x200"
                                      width="200"
                                      src={driver.insurance_img}
                                      data-holder-rendered="true"
                                    />
                                  </dt>
                                </>
                              )}
                              { driver.insurance_provider_img && (
                                <>
                                  <dd className="col-2">Insurance Provider</dd>
                                  <dt className="col-2">
                                    <img
                                      className="rounded me-2"
                                      alt="200x200"
                                      width="200"
                                      src={driver.insurance_provider_img}
                                      data-holder-rendered="true"
                                    />
                                  </dt>
                                </>
                              )}
                              { driver.insurance_expiry_img && (
                                <>
                                  <dd className="col-2">Insurance Expiry Date</dd>
                                  <dt className="col-2">
                                    <img
                                      className="rounded me-2"
                                      alt="200x200"
                                      width="200"
                                      src={driver.insurance_expiry_img}
                                      data-holder-rendered="true"
                                    />
                                  </dt>
                                </>
                              )}
                            </div>
                          </div>
                          <hr />
                          <div className="row mb-5">
                            <div className="row">
                              { driver.license_img && (
                                <>
                                  <dd className="col-2">Driving License</dd>
                                  <dt className="col-2">
                                    <img
                                      className="rounded me-2"
                                      alt="200x200"
                                      width="200"
                                      src={driver.license_img}
                                      data-holder-rendered="true"
                                    />
                                  </dt>
                                </>
                              )}
                              { driver.license_expiry_img && (
                                <>
                                  <dd className="col-2">
                                    Driving License Expiry Date
                                  </dd>
                                  <dt className="col-2">
                                    <img
                                      className="rounded me-2"
                                      alt="200x200"
                                      width="200"
                                      src={driver.license_expiry_img}
                                      data-holder-rendered="true"
                                    />
                                  </dt>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="d-flex flex-wrap gap-2">
                              <Button
                                type="submit"
                                color="primary"
                                onClick={handleSendNotification}
                              >
                                Send Notifications
                              </Button>
                              <SendNotificationPopup
                                driverId={params.driverId}
                                open={viewNofiPopup}
                                onClose={handleSendNotificationClose}
                              />
                              {state !== 1 && (
                                <>
                                  <Button
                                    type="submit"
                                    color="success"
                                    onClick={handleApproveProfile}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    type="submit"
                                    color="warning"
                                    onClick={handleDisapproveProfile}
                                  >
                                    Disapprove
                                  </Button>
                                </>
                              )}
                              {/* <Button type="submit" color="primary" disabled={true}>
                            Call Driver
                          </Button> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="alert alert-danger fade show text-center">
                            Driver Profile is not complete yet
                          </div>
                        </>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewDriver
