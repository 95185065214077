import React, { useEffect } from "react"
import { Container, Row, Card, CardBody } from "reactstrap"
import { useHistory } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"

function Setting(props) {
  const history = useHistory()

  useEffect(() => {
    
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Settings" />
          <Row>
            <Card>
              <CardBody>This Page in under development</CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Setting
